/* You can add global styles to this file, and also import other style files */

/* Set a background image by replacing the URL below */
body {
    background: url('assets/images/backgrounds/background.jpg') fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #EEB973;
  }

.bg-0 {
    background-color: rgba(245, 245, 245, 0.9) !important;
}

//.bg-1 {
//    background-color: rgba(238, 99, 82, 0.9) !important;
//}

.bg-2 {
    background-color: rgba(89, 205, 144, 0.9) !important;
}
.bg-3 {
    background-color: rgba(63, 167, 214, 0.9) !important;
}

.bg-4 {
    background-color: rgba(247, 157, 132, 0.9) !important;
}

.bg-sand {
    background-color: #EEB973 !important;
}
.background-header {
    background-color: rgba(63, 167, 214, 0.9) !important;
}

@font-face {
    font-family: 'grandstander-regular';
    src: url('assets/fonts/Grandstander-Regular.ttf');
}

div.grandstand {
    font-family: 'Grandstander-regular', sans-serif;
}


/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
